<template>
  <div>
    <!-- 配置按钮 -->
    <i class="el-icon-setting config" @click="showConfig"></i>
    <!-- 弹窗 -->
    <el-dialog
      title="地图配置"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :modal="false"
      :close-on-click-modal="false"
      >
      
      <!-- 表单内容 -->
      <el-form :model="form" :rules="rules" ref="configForm" label-width="120px">
        <el-form-item label="参考点1经纬度" prop="point1">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-input v-model="form.point1Lng" placeholder="经度"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input v-model="form.point1Lat" placeholder="纬度"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        
        <el-form-item label="参考点1坐标" prop="f1">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-input v-model="form.f1X" placeholder="X坐标"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input v-model="form.f1Y" placeholder="Y坐标"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        
        <el-form-item label="参考点2经纬度" prop="point2">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-input v-model="form.point2Lng" placeholder="经度"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input v-model="form.point2Lat" placeholder="纬度"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        
        <el-form-item label="参考点2坐标" prop="f2">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-input v-model="form.f2X" placeholder="X坐标"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input v-model="form.f2Y" placeholder="Y坐标"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        
        <el-form-item label="中心点坐标" prop="center">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-input v-model="form.centerX" placeholder="X坐标"></el-input>
            </el-col>
            <el-col :span="10">
              <el-input v-model="form.centerY" placeholder="Y坐标"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <!-- 弹窗底部操作按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading">确认</el-button>
        <el-button type="warning" @click="resetToDefault">恢复默认值</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'mapConfig',
  data() {
    return {
      dialogVisible: false, // 控制弹窗显示
      form: {
        point1Lng: '113.7371852',
        point1Lat: '23.1469021',
        f1X: '0.32',
        f1Y: '0.12',
        point2Lng: '114.1861943',
        point2Lat: '22.6610268',
        f2X: '0.85',
        f2Y: '0.95',
        centerX: '0.22',
        centerY: '0.27'
      },
      defaultForm: {
        point1Lng: '113.7371852',
        point1Lat: '23.1469021',
        f1X: '0.32',
        f1Y: '0.12',
        point2Lng: '114.1861943',
        point2Lat: '22.6610268',
        f2X: '0.85',
        f2Y: '0.95',
        centerX: '0.22',
        centerY: '0.27'
      },
      rules: {
        point1Lng: [{ required: true, message: '请输入参考点1经度', trigger: 'blur' }],
        point1Lat: [{ required: true, message: '请输入参考点1纬度', trigger: 'blur' }],
        f1X: [{ required: true, message: '请输入参考点1图片X坐标', trigger: 'blur' }],
        f1Y: [{ required: true, message: '请输入参考点1图片Y坐标', trigger: 'blur' }],
        point2Lng: [{ required: true, message: '请输入参考点2经度', trigger: 'blur' }],
        point2Lat: [{ required: true, message: '请输入参考点2纬度', trigger: 'blur' }],
        f2X: [{ required: true, message: '请输入参考点2图片X坐标', trigger: 'blur' }],
        f2Y: [{ required: true, message: '请输入参考点2图片Y坐标', trigger: 'blur' }],
        centerX: [{ required: true, message: '请输入中心点X坐标', trigger: 'blur' }],
        centerY: [{ required: true, message: '请输入中心点Y坐标', trigger: 'blur' }]
      },
      loading: false
    };
  },
  created() {
    // 从本地存储加载配置
    this.loadConfig();
  },
  methods: {
    showConfig() {
      this.dialogVisible = true; // 显示弹窗
    },
    handleClose() {
      this.dialogVisible = false; // 关闭弹窗
    },
    submitForm() {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          
          // 保存配置到本地存储
          this.saveConfig();
          
          // 发送配置更新事件
          const mapConfig = {
            point1: [parseFloat(this.form.point1Lng), parseFloat(this.form.point1Lat)],
            f1: [parseFloat(this.form.f1X), parseFloat(this.form.f1Y)],
            point2: [parseFloat(this.form.point2Lng), parseFloat(this.form.point2Lat)],
            f2: [parseFloat(this.form.f2X), parseFloat(this.form.f2Y)],
            centerPoint: [parseFloat(this.form.centerX), parseFloat(this.form.centerY)]
          };
          
          this.$emit('configUpdated', mapConfig);
          this.$baseMessage('配置已更新', 'success');
          this.handleClose();
          this.loading = false;
          
          // 添加页面刷新
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          return false;
        }
      });
    },
    resetToDefault() {
      // 重置为默认值
      this.form = JSON.parse(JSON.stringify(this.defaultForm));
      this.$baseMessage('已恢复默认配置', 'info');
    },
    saveConfig() {
      // 保存配置到本地存储
      localStorage.setItem('mapConfig', JSON.stringify(this.form));
    },
    loadConfig() {
      // 从本地存储加载配置
      const savedConfig = localStorage.getItem('mapConfig');
      if (savedConfig) {
        try {
          const config = JSON.parse(savedConfig);
          this.form = { ...this.form, ...config };
        } catch (e) {
          console.error('加载地图配置失败', e);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
}

.config {
  font-size: 1rem;
  width: 1.3rem;
  padding-left: 5px;
  cursor: pointer;
}

.config:hover {
  color: #2d6bdf;
  font-size: 1.1rem;
}
</style>