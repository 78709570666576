<template>
   <div class="MiddleView">
    <div class="top">
       <div v-for="item in oilSalesData" :key="item.goods_no" class="oilSales">
            <div class="title">
                {{item.goods_no}}#销量 (吨)
            </div>
            <div class="num">
                <eNumber :num="item.pay_ton" ></eNumber>
            </div>
       </div>
    </div>
    <div class="middle">
        <dv-flyline-chart v-if="stations.length" :config="config" style="width:100%;height:100%;" />
        <mapConfig @configUpdated="updateMapConfig"></mapConfig>
    </div>
    <div class="bottom">
        <div class="card">
            <div class="boxHeader">
                <div class="title" @click="fetchData">
                    销售增长趋势
                    <span class="bold">{{ trendType == 1 ? '[油品]':'[非油]' }}</span>
                </div>
                <div>
                    <xxyButton v-model="trendType" :active="1">油品</xxyButton>
                    <xxyButton v-model="trendType" :active="2">非油</xxyButton>
                </div>
            </div>
            <div class="box" ref="increceOilTrend">
            </div>
        </div>
    </div>
   </div>
</template>

<script>
import eNumber from '@/components/e-number2.vue'
import {getMiddle} from '@/utils/api'
import xxyButton from '@/components/xxy-button.vue'
import { mapActions, mapGetters } from 'vuex'
import mapConfig from '@/components/setting.vue'

export default {
    name: 'MiddleView',
    components: {
        eNumber,
        xxyButton,
        mapConfig
    },
    data() {
        return {
            oilSalesData: [
                {
                    "pay_goods_no": "OG0",
                    "pay_ton": 0.0,
                    "goods_no": "0",
                    "goods_name": "0#"
                },
                {
                    "pay_goods_no": "OG92",
                    "pay_ton": 0.0,
                    "goods_no": "92",
                    "goods_name": "92#"
                },
                {
                    "pay_goods_no": "OG95",
                    "pay_ton": 0.0,
                    "goods_no": "95",
                    "goods_name": "95#"
                },
                {
                    "pay_goods_no": "OG98",
                    "pay_ton": 0.0,
                    "goods_no": "98",
                    "goods_name": "98#"
                }
            ],
            trendType: 1,
            oilTrendData: {
                oil: [],
                notOil: []
            },
            trendData: [],
            trendChart: null,
            trendColor: ['#2f83e4', '#00e5c1', '#66ccff', '#23cbff',],
            mapConfig: {
                point1: [113.7371852, 23.1469021],
                f1: [0.32, 0.12],
                point2: [114.1861943, 22.6610268],
                f2: [0.85, 0.95],
                centerPoint: [0.22, 0.27]
            },
        }
    },
    computed: {
        ...mapGetters({
            stations: 'login/stations',
        }),
        config(){
            var points = this.stations.map(s => {
             return {
                    text: s.real_name.replace('加油站', ''),
                    position: this.latLngToImageCoord(s.latitude, s.longitude)
                }
            })
            console.log('points', points)

            const enterprise_no = localStorage.getItem('enterprise_no');
            const bgImgUrl =  require(`@/assets/${enterprise_no}_map.png`);

            return {
                centerPoint: this.mapConfig.centerPoint,
                points,
                bgImgUrl,
                centerPointImg: {
                    url: require('@/assets/loc.png'),
                },
                pointsImg: {
                    url: require('@/assets/loc.png'),
                },
                flylineColor: '#27e9ff',
                text:{
                    color: '#FFFFFF'
                }
            }
        }
    },
    created() {
        this.loadMapConfig();
    },
    watch:{
        trendType(){
            this.generateTrendData()
        },
        oilTrendData(){
            this.generateTrendData()
        }
    },
    methods:{
        latLngToImageCoord(lat, lng) {
            // 使用配置中的参考点
            const point1 = this.mapConfig.point1;
            const f1 = this.mapConfig.f1;
            const point2 = this.mapConfig.point2;
            const f2 = this.mapConfig.f2;

            // 计算经纬度与图片坐标之间的比例
            const lngRatio = (lng - point1[0]) / (point2[0] - point1[0]);
            const latRatio = (lat - point1[1]) / (point2[1] - point1[1]);

            // 计算新经纬度点对应的图片坐标
            const x = f1[0] + lngRatio * (f2[0] - f1[0]);
            const y = f1[1] + latRatio * (f2[1] - f1[1]);

            // 返回图片坐标
            return [x, y];
        },
        updateMapConfig(config) {
            this.mapConfig = config;
            localStorage.setItem('mapConfigData', JSON.stringify(config));
        },
        loadMapConfig() {
            const savedConfig = localStorage.getItem('mapConfigData');
            if (savedConfig) {
                try {
                    this.mapConfig = JSON.parse(savedConfig);
                } catch (e) {
                    console.error('加载地图配置失败', e);
                }
            } else {
                // 尝试从 mapConfig 组件的本地存储加载
                const settingConfig = localStorage.getItem('mapConfig');
                if (settingConfig) {
                    try {
                        const config = JSON.parse(settingConfig);
                        this.mapConfig = {
                            point1: [parseFloat(config.point1Lng), parseFloat(config.point1Lat)],
                            f1: [parseFloat(config.f1X), parseFloat(config.f1Y)],
                            point2: [parseFloat(config.point2Lng), parseFloat(config.point2Lat)],
                            f2: [parseFloat(config.f2X), parseFloat(config.f2Y)],
                            centerPoint: [parseFloat(config.centerX), parseFloat(config.centerY)]
                        };
                    } catch (e) {
                        console.error('加载地图配置失败', e);
                    }
                }
            }
        },
        intervalChange(){
            this.trendType = this.trendType == 1? 2: 1;
        },
        fetchData(){
            return new Promise((resolve, reject) => {
                getMiddle().then(res => {
                    this.oilSalesData = res.data.oilSales;
                    this.oilTrendData = res.data.increceOilTrend;
                }).catch(err => {
                    console.log('err', err)
                }).finally(() => {
                    resolve();
                })
            })
        },
        generateTrendData(){
            this.trendData = this.trendType == 1 ? this.oilTrendData.oil : this.oilTrendData.notOil
            this.initTrend()
        },
        initTrend(){
            var chartDom = this.$refs.increceOilTrend;
            if(!this.trendChart){
                this.trendChart = this.$echarts.init(chartDom);
            }
            var option;
            var cusLegend = {
                orient: 'horizontal',
                top: 10,
                textStyle: {
                    color: '#fff',
                    fontSize: '16px'
                },
                data: this.trendType == 1 ? ['0#','92#','95#','98#'] : ['非油销售额']
            }
            option = {
            color: this.trendColor,
            title: {
            },
            legend: cusLegend,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: this.trendData.keys
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        lineStyle:{
                            color: 'rgba(255,255,255, 0.15)'
                        }
                    }
                }
            ],
            series: Object.keys(this.trendData.data).map((key,index) => {
                    return {
                        name: key,
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            opacity: 0.8,
                        },
                        showSymbol: false,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.trendData.data[key],
                        smooth: true,
                    }
                }),
            };
            option && this.trendChart.setOption(option);
        }
    }
}
</script>
<style lang="scss" scoped>
.MiddleView {
    height: 100%;
    display: flex;
    flex-direction: column;

    .top {
        flex: 1;
        display: flex;

        .oilSales {
            flex: 1;
            text-align: center;
            margin: 0 5px;

            .title {
                color: #fff;
                border: 1px solid #67D0F2;
                font-size: 1.1rem;
                padding: 6px 0;
            }
        }
    }

    .middle {
        flex: 6;
    }

    .bottom {
        flex: 4;
        display: flex;
    }
}
</style>
